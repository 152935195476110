:root {
    --primary-color: #2c3e50;
    --secondary-color: #3498db;
    --accent-color: #e74c3c;
    --text-color: #333;
    --light-bg: #f8f9fa;
    --white: #ffffff;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    line-height: 1.6;
    color: var(--text-color);
}

/* Header & Navigation */
header {
    background: var(--white);
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: fixed;
    width: 100%;
    z-index: 1000;
}

nav {
    max-width: 1200px;
    margin: 0 auto;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo img {
    height: 40px;
    width: auto;
    vertical-align: middle;
}

.logo a {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1.5rem;
    font-weight: 700;
    color: var(--primary-color);
    text-decoration: none;
}

.nav-links a {
    color: var(--primary-color);
    text-decoration: none;
    margin-left: 2rem;
    font-weight: 500;
    transition: color 0.3s ease;
}

.nav-links a:hover {
    color: var(--secondary-color);
}

/* Hero Section */
.hero {
    padding: 8rem 2rem 4rem;
    background: linear-gradient(135deg, var(--primary-color), var(--secondary-color));
    color: var(--white);
    text-align: center;
}

.hero-content {
    max-width: 800px;
    margin: 0 auto;
}

.hero h1 {
    font-size: 3rem;
    margin-bottom: 1rem;
}

.hero p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
}

/* Buttons */
.cta-button {
    display: inline-block;
    padding: 0.8rem 2rem;
    background: var(--accent-color);
    color: var(--white);
    text-decoration: none;
    border-radius: 5px;
    transition: background 0.3s ease;
}

.cta-button:hover {
    background: #c0392b;
}

.cta-button.secondary {
    background: transparent;
    border: 2px solid var(--white);
    margin-left: 1rem;
}

.cta-button.secondary:hover {
    background: var(--white);
    color: var(--primary-color);
}

/* Services Section */
.services {
    padding: 4rem 2rem;
    background: var(--light-bg);
}

.service-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 2rem auto;
}

.service-card {
    background: var(--white);
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    transition: transform 0.3s ease;
}

.service-card:hover {
    transform: translateY(-5px);
}

.service-card i {
    font-size: 2.5rem;
    color: var(--secondary-color);
    margin-bottom: 1rem;
}

/* Features Section */
.features {
    padding: 6rem 2rem;
    background: var(--white);
    position: relative;
}

.feature-list {
    max-width: 800px;
    margin: 3rem auto;
    background: var(--light-bg);
    padding: 2rem;
    border-radius: 15px;
    box-shadow: 0 2px 15px rgba(0,0,0,0.1);
}

.feature-item {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem;
    padding: 1rem;
    background: var(--white);
    border-radius: 8px;
    transition: transform 0.3s ease;
}

.feature-item:hover {
    transform: translateX(10px);
}

.feature-item i {
    color: var(--secondary-color);
    margin-right: 1.5rem;
    font-size: 1.5rem;
    background: var(--light-bg);
    padding: 1rem;
    border-radius: 50%;
}

.feature-item:last-child {
    margin-bottom: 0;
}

/* Pricing Section */
.pricing {
    padding: 4rem 2rem;
    background: var(--light-bg);
}

.pricing-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    max-width: 900px;
    gap: 2rem;
    margin: 2rem auto;
}

.pricing-card {
    background: var(--white);
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.pricing-card.featured {
    transform: scale(1.05);
    border: 2px solid var(--secondary-color);
}

.pricing-card .price {
    font-size: 2.5rem;
    color: var(--primary-color);
    margin: 1rem 0 0.5rem;
}

.pricing-card .price span {
    font-size: 1.2rem;
    opacity: 0.8;
}

.price-subtitle {
    color: var(--accent-color);
    font-size: 0.9rem;
    margin-bottom: 1rem;
}

.price-subtitle i {
    font-size: 0.9rem;
    cursor: help;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.price-subtitle i:hover {
    opacity: 1;
}

.pricing-card ul {
    list-style: none;
    text-align: left;
    padding: 0 1rem;
    flex-grow: 1; /* This makes the list take up available space */
}

.pricing-card ul li {
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
}

.pricing-card ul li i {
    color: var(--secondary-color);
    margin-right: 0.5rem;
}

.pricing-card > *:not(:last-child) {
    margin-bottom: 1rem;
}

/* Contact Section */
.contact {
    padding: 4rem 2rem;
    text-align: center;
}

.contact-info {
    max-width: 800px;
    margin: 2rem auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

.contact-item {
    background: var(--light-bg);
    padding: 2rem;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.contact-item:hover {
    transform: translateY(-5px);
}

.contact-item i {
    font-size: 2.5rem;
    color: var(--secondary-color);
    margin-bottom: 1rem;
}

.contact-item h3 {
    margin-bottom: 1rem;
    color: var(--primary-color);
}

.contact-item a {
    color: var(--accent-color);
    text-decoration: none;
    font-weight: 500;
    transition: color 0.3s ease;
}

.contact-item a:hover {
    color: var(--primary-color);
}

.contact-item p {
    margin-top: 0.5rem;
    font-size: 0.9rem;
    color: var(--text-color);
}

#contact-form {
    max-width: 600px;
    margin: 0 auto;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group input,
.form-group textarea {
    width: 100%;
    padding: 0.8rem;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.form-group textarea {
    height: 150px;
}

/* Contact specific styles */
.contact-item .fa-reddit {
    color: #FF4500;
    transition: color 0.3s ease;
}

.contact-item:hover .fa-reddit {
    color: #FF5700;
}

/* Modules Section */
.modules {
    padding: 6rem 2rem;
    background: var(--light-bg);
    position: relative;
}

.module-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 2rem auto;
}

.module-card {
    background: var(--white);
    padding: 2rem;
    border-radius: 10px;
    text-align: center;
    transition: transform 0.3s ease;
    box-shadow: 0 2px 15px rgba(0,0,0,0.1);
}

.module-card:hover {
    transform: translateY(-5px);
}

.module-card i {
    font-size: 3rem;
    color: var (--secondary-color);
    margin-bottom: 1.5rem;
}

/* Tooltip */
.tooltip-wrapper {
    position: relative;
    display: inline-block;
}

.tooltip-wrapper i {
    color: var(--secondary-color);
    font-size: 0.9rem;
    cursor: help;
    opacity: 0.8;
    transition: opacity 0.3s ease;
}

.pricing-card a.cta-button .tooltip-wrapper i {
    color: var(--white);
}

.tooltip-wrapper i:hover {
    opacity: 1;
}

.tooltip {
    visibility: hidden;
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background-color: var(--primary-color);
    color: var(--white);
    text-align: center;
    padding: 0.5rem 1rem;
    border-radius: 6px;
    width: 200px;
    font-size: 0.85rem;
    line-height: 1.4;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s;
}

.tooltip::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: var(--primary-color) transparent transparent transparent;
}

.tooltip-wrapper:hover .tooltip {
    visibility: visible;
    opacity: 1;
}

/* Disclaimer Section */
.disclaimer {
    padding: 3rem 2rem;
    background: var(--light-bg);
    text-align: center;
}

.disclaimer-content {
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem;
    border: 1px solid var(--accent-color);
    border-radius: 10px;
}

/* Platform Section */
.platforms {
    padding: 4rem 2rem;
    text-align: center;
    background: var(--white);
}

.platform-logos-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 3rem;
    margin: 2rem auto;
    max-width: 800px;
}

.platform-logo-wrapper {
    background: var(--light-bg);
    padding: 2rem;
    border-radius: 10px;
    transition: transform 0.3s ease;
    box-shadow: 0 2px 15px rgba(0,0,0,0.05);
}

.platform-logo-wrapper:hover {
    transform: translateY(-5px);
}

.platform-logo {
    height: 40px;
    width: auto;
    object-fit: contain;
}

.platform-description {
    max-width: 600px;
    margin: 2rem auto 0;
    color: var(--text-color);
    font-size: 1.1rem;
    line-height: 1.6;
}

/* Screenshots Section */
.screenshots {
    padding: 4rem 2rem;
    background-color: #f8f9fa;
}

.screenshots h2 {
    text-align: center;
    margin-bottom: 3rem;
}

.screenshot-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
    max-width: 1200px;
    margin: 0 auto;
}

.screenshot-item {
    text-align: center;
}

.screenshot-item img {
    width: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
}

.screenshot-item img:hover {
    transform: scale(1.05);
}

.screenshot-item p {
    margin-top: 1rem;
    color: #666;
    font-size: 0.9rem;
}

/* Modal styles */
.modal {
    display: none;
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
}

.modal.show-bg {
    background-color: rgba(0, 0, 0, 0.9);
}

.modal-content {
    max-width: 90%;
    max-height: 90vh;
    opacity: 0;
    transform: scale(0.8);
    transition: all 0.3s ease-out;
    object-fit: contain;
}

.modal-content.show {
    opacity: 1;
    transform: scale(1);
}

.modal-close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    cursor: pointer;
}

.modal-close:hover,
.modal-close:focus {
    color: #bbb;
    text-decoration: none;
    cursor: pointer;
}

/* Footer */
footer {
    background: var(--primary-color);
    color: var(--white);
    padding: 4rem 2rem 2rem;
}

.footer-content {
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 2rem;
}

.footer-section h4 {
    margin-bottom: 1rem;
}

.footer-section a {
    color: var(--white);
    text-decoration: none;
    display: block;
    margin-bottom: 0.5rem;
}

.social-links a {
    display: inline-block;
    margin-right: 1rem;
    font-size: 1.5rem;
}

/* Footer social links specific colors */
.social-links a .fa-reddit {
    background: white;
    border-radius: 50%;
    padding: 1px; /* Reduced from 5px to 3px */
    color: #FF4500;
}

.social-links a:hover .fa-reddit {
    color: #FF5700;
}

.footer-bottom {
    text-align: center;
    margin-top: 2rem;
    padding-top: 2rem;
    border-top: 1px solid rgba(255,255,255,0.1);
}

/* Hamburger Menu */
.hamburger {
    display: none;
    cursor: pointer;
    padding: 0.5rem;
    z-index: 1001;
}

.hamburger span {
    display: block;
    width: 25px;
    height: 3px;
    background: var(--primary-color);
    margin: 5px 0;
    transition: all 0.3s ease;
    border-radius: 3px;
}

/* Responsive Design */
@media (max-width: 768px) {
    .nav-links {
        display: none;
        position: fixed;
        top: 70px;
        left: 0;
        right: 0;
        background: var(--white);
        padding: 1rem;
        box-shadow: 0 2px 4px rgba(0,0,0,0.1);
        z-index: 1000;
    }

    .nav-links.active {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .nav-links a {
        margin: 0.5rem 0;
        padding: 0.5rem 1rem;
        width: 100%;
        text-align: center;
    }

    .hero h1 {
        font-size: 2rem;
    }

    .pricing-card.featured {
        transform: none;
    }

    .pricing-grid {
        grid-template-columns: 1fr;
    }

    .hero,
    .screenshots,
    .platforms,
    .modules,
    .features,
    .pricing,
    .disclaimer,
    .contact {
        padding: 3rem 1rem;  /* Reduce padding on mobile */
        width: 100%;         /* Ensure full width */
        overflow: hidden;    /* Prevent horizontal scroll */
    }

    .hero-content,
    .screenshot-grid,
    .platform-logos-container,
    .module-grid,
    .feature-list,
    .pricing-grid,
    .contact-info {
        width: 100%;        /* Full width on mobile */
        padding: 0;         /* Remove padding */
        margin: 0 auto;     /* Center content */
    }

    .screenshot-item {
        max-width: 100%;    /* Full width images */
        margin: 0 auto 2rem;/* Add bottom margin between items */
    }

    .platform-logos-container {
        flex-direction: column; /* Stack logos vertically */
        gap: 1.5rem;
    }

    .pricing-card {
        margin: 0 0 1.5rem;  /* Add space between cards */
    }

    /* Adjust tooltip position for mobile */
    .tooltip {
        width: 160px;      /* Narrower tooltips on mobile */
        left: 50%;
        transform: translateX(-50%);
    }

    .hamburger {
        display: block;
    }

    .hamburger.active span:nth-child(1) {
        transform: rotate(45deg) translate(5px, 5px);
    }

    .hamburger.active span:nth-child(2) {
        opacity: 0;
    }

    .hamburger.active span:nth-child(3) {
        transform: rotate(-45deg) translate(7px, -6px);
    }
}

/* Add smaller screen breakpoint for extra narrow devices */
@media (max-width: 480px) {
    .hero {
        padding-top: 6rem; /* Account for fixed header */
    }

    .hero h1 {
        font-size: 1.75rem;
    }

    .hero p {
        font-size: 1rem;
    }

    .hero-buttons {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    .cta-button.secondary {
        margin-left: 0;
    }
}
